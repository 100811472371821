<template>
  <div class="examPaperContent">
    <paper
      ref="paper"
      v-if="questionList.length>0"
      :headTitle='headTitle'
      :questionList='questionList'
      :remainTime='remainTime'
      :sheetBtnName='sheetBtnName'
      @saveAnswer='saveAnswer'
      @submit='submit'
      @handcollect='handcollect'
      @updateScore='updateScore'
      @changeRemarkRead='changeRemarkRead'
    ></paper>
  </div>
</template>

<script>
import paper from '../paper'
import { Toast, Dialog, Notify } from "vant";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: 'specialPaper',
  components: {
    paper
  },
  data () {
    return {
      questionList: [],
      headTitle: '本地测试',
      sheetBtnName: ['提交'],
      remainTime: '',
      queryObj: {
        // examType: 1, // 0 查看解析 1 重考
        type: 1, // 考试类型
        status: 1, // 考试状态
        courseId: 1202, // 收藏需要用
        paperId: 16059, // 试卷id
        // paperId: 14093 // 试卷id
      }
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      const type = this.$store.state.deviceType
      if (val && type === 1) {
        // ios
        window.webkit.messageHandlers.getData.postMessage('examQuery')
        window.getIosQuery = this.getIosQuery
      } else if (val && type === 2) {
        // 安卓
        this.getAnQuery()
      } else {
        // 本地
        this.getPaperList()
      }
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    // ios获取参数
    getIosQuery (query) {
      this.queryObj = JSON.parse(query)
      this.getPaperList()
    },
    // 安卓获取参数
    getAnQuery () {
      const AnQuery = window.Android.getAnQuery()
      this.queryObj = JSON.parse(AnQuery)
      this.getPaperList()
    },
    // 变化考试状态
    getPaperType (data) {
      if (this.queryObj.status == 0) {
        // 开始考试
        return this.$request.putHelpStudyExamBegin(data)
      } else if (this.queryObj.status == 1) {
        // 考试中
        return this.$request.putHelpStudyExamContinue(data)
      } else if (this.queryObj.status == 2) {
        // 批阅2
        return this.$request.putHelpStudyExamReadRemark(data)
      } else if(this.queryObj.status == 3 || this.queryObj.status == 4) {
        // 及格3 不及格4
        if(this.queryObj.hasOwnProperty('examType') && this.queryObj.examType == 1)
          return this.$request.putHelpStudyExamAgain(data)
        else if(this.queryObj.hasOwnProperty('examType') && this.queryObj.examType == 0)
          return this.$request.putHelpStudyExamReadRemark(data)
      }
    },
    // 获取考试信息以及题目列表
    getPaperList () {
      Toast.loading({
        duration: 0,
        message: "题目生成中",
      })
      let data = {
        paperId: this.queryObj.paperId,
      }
      this.getPaperType(data).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data, 'putHelpStudyExamBegin')
          this.questionList = res.data.data.questions || []
          this.headTitle = res.data.data.paperName
          if (this.queryObj.status === 2) {
            this.remainTime = '批阅/自改'
          } else if (this.queryObj.status === 3 || this.queryObj.status === 4) {
            this.remainTime = this.queryObj.examType == 1 ? res.data.data.remainTime : '预览'
          } else {
            this.remainTime = res.data.data.remainTime
          }
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        Toast.clear()
      })
    },
    // 做题提交
    saveAnswer (question) {
      console.log(question,'qus')
      let obj = question
      if ((question.typeId == 7 || question.typeId == 8) && question.childrenList.length>0) {
        obj = question.childrenList[question.miniNum]
      }
      this.$request.postHelpStudyExamAnswer({
        paperId: this.queryObj.paperId,
        questionId: obj.questionId,
        selfAnswer: obj.selfAnswer
      }).then((res) => {
        this.$refs.paper.saveCallback(question, question)
      }).catch((err) => {
        
        });
    },
    // 提交考试
    getSubmitType (data) {
      if (this.queryObj.status == 1 || this.queryObj.status==0) {
        // 考试中
        return this.$request.putHelpStudyExamEnd(data)
      } else if (this.queryObj.status === 2) {
        // 批阅
        return this.$request.putHelpStudyReviewEnd(data)
      } else if((this.queryObj.status == 3 || this.queryObj.status == 4) && this.queryObj.examType == 1) {
        // 重考提交
        return this.$request.putHelpStudyExamEnd(data)
      }
    },
    submit (val) {
      let data = {
        paperId: this.queryObj.paperId
      }
      this.getSubmitType(data).then((res) => {
        Notify({ type: 'success', message: '提交成功' })
        const type = this.$store.state.deviceType
        if (val === 'submitSeft') {
          // 主动提交
          this.$refs.paper.submitCallback()
        }
        if (type === 1) {
          window.webkit.messageHandlers.getData.postMessage('close()')
        } else if (type === 2) {
          window.Android.Close()
        }
      }).catch((err) => {
        Notify({ type: 'danger', message: '提交失败' })
      })
    },
    // 批阅改对错
    changeRemarkRead (val) {
      let data = {
        isTrue: val.trueFlag == 1 ? 'true' : 'false',
        paperId: this.queryObj.paperId,
        questionType: val.typeId,
        parentId: val.parentTypeId ? val.parentId : 0,
        questionId: val.questionId
      }
      if (this.queryObj.status==2) {
        this.$request.putHelpStudyReviewJudge(data).then((res) => {
          this.$refs.paper.remarkReadCallback(val)
        }).catch((err) => {
        })
      }
    },
    // 批阅给分
    updateScore (val) {
      let data = {
        paperId: this.queryObj.paperId,
        questionId: val.questionId,
        selfScore: val.selfScore
      }
      if (this.queryObj.status==2) {
        this.$request.putHelpStudyReview(data).then((res) => {
          this.$refs.paper.reviewCallback(val)
        }).catch((err) => {
        })
      }
    },
    // 收藏事件
    handCollect (val) {
      let data = {
        action: 0,
        courseId: val.courseId,
        questionId: val.questionId,
        source: val.source
      }
      console.log(data, 'data')
      this.$request.postHelpStudyQuestionCollect(data).then((res) => {
        Notify({ type: 'success', message: '收藏成功' })
      }).catch((err) => {
        Notify({ type: 'danger', message: '收藏失败' })
      })
    },
    handcollect(val) {
      val.source = this.queryObj.type
      val.type = 1
      this.$request.postHelpStudyQuestionCollect(val).then(res => {
        if(res.data.code == 0) {
          Notify({ type: 'success', message: val.action == 1 ? '收藏成功' : '取消收藏' })
          this.$refs.paper.failCollect('success')
        } else {
          Notify({ type: 'success', message: '收藏失败' })
          this.$refs.paper.failCollect('fail')
        }
      })
    }
  },
}
</script>

<style>

</style>